/**
 * t('common:errors.401.text')
 * t('common:errors.401.title')
 * t('common:errors.401.link-back')
 * t('common:errors.404.text')
 * t('common:errors.404.title')
 * t('common:errors.404.link-back')
 * t('common:errors.500.text')
 * t('common:errors.500.title')
 * t('common:errors.500.link-back')
 *
 */

import React, { ReactElement } from 'react';
import {
  FontSecondarySemiBold, spacingBottom,
  spacingTop, spacingTopBottom,
} from '@digando/react-component-library';
import styled from '@emotion/styled';
import { Trans, useTranslation } from 'next-i18next';
import { useAuthContext } from '../../store/auth.context';
import { ContentContainerMax, ContentContainerSmall } from '../../layout/container';
import { mediaQueries } from '../../styles/media-queries';
import { DrawerStep } from '../sidebar/interfaces/drawer.interfaces';
import { useRouter } from 'next/router';
import { StrapiSeo } from '../../seo/strapi-seo';
import NextImage from 'next/image';
import ErrorPageIsland from './fehlerseite_insel.svg';
import { Button } from '@digando/common-ui';
import { useCategoriesContext } from '../../store/categories.context';
import { IPopularCategory, PopularCategoriesStatic } from '../popular-categories/popular-categories-static';
import { ComponentContentPopularCategories } from '../../@types/codegen/graphql';

export interface IErrorProps {
  statusCode: number;
  popularCategories?: ComponentContentPopularCategories;
}

export const ErrorPageTemplate = (props: IErrorProps): ReactElement => {
  const { statusCode, popularCategories } = props;
  const { setIsSignInVisible, setSignInStep } = useAuthContext();
  const [t] = useTranslation(['common']);
  const router = useRouter();

  let showCategories = false;
  const { categories } = useCategoriesContext();

  const filteredPopularCategories: IPopularCategory[] = [];
  popularCategories?.categories?.map(category => {
    const categoryData = categories.find(c => c.uriComponent === category?.slug);

    if (categoryData) {
      filteredPopularCategories.push({
        id: categoryData.id,
        title: categoryData.searchTitle,
        imageUrl: categoryData?.image?.url ?? '',
        relativeUrl: categoryData.relativeUrl,
      });
    }
  });

  if (404 === statusCode && filteredPopularCategories) {
    showCategories = true;
  }

  let title = 'Error ' + statusCode;
  let text = 'An error occured on server.';

  if (statusCode === 401 || statusCode === 404 || statusCode === 500) {
    title = t(`common:errors.${statusCode}.title`);
    text = t(`common:errors.${statusCode}.text`);
  }

  const error = (
    <>
      <ErrorContainer showCategories={showCategories} statusCode={statusCode}>
        <TitleContainer>
          <ErrorTitle>{title}</ErrorTitle>
        </TitleContainer>

        {500 === statusCode ? (
          <TextContainer>
            <ContentContainerSmall>
              {text}
            </ContentContainerSmall>
            <ContentContainerSmall>
              <Trans t={t} i18nKey={'common:errors.500.text2'}>
                Error in translation.
                <a href={'mailto:' + t('common:supportEmailAddress')}></a>
              </Trans>
            </ContentContainerSmall>
            <ContentContainerSmall>
              {t('common:errors.500.text3')}
            </ContentContainerSmall>
          </TextContainer>
        ) : (
          <TextContainer>
            <ContentContainerSmall>
              {text}
            </ContentContainerSmall>
          </TextContainer>
        )}

        {(404 === statusCode || 500 === statusCode) && (
          <ButtonContainer>
            <Button onClick={() => router.push('/')}>
              {t(`common:errors.${statusCode}.link-back`).toUpperCase()}
            </Button>
          </ButtonContainer>
        )}

        {401 === statusCode && (
          <ButtonContainer>
            <Button onClick={(): void => {
              setSignInStep(DrawerStep.SIGN_IN);
              setIsSignInVisible(true);
            }}>
              {t(`common:errors.${statusCode}.link-back`).toUpperCase()}
            </Button>
          </ButtonContainer>
        )}

        <ImageContainer>
          <NextImage
            src={ErrorPageIsland.src}
            priority={true}
            unoptimized={true}
            alt={'Fehlerseite Insel'}
            title={'Fehlerseite Insel'}
            width={1080}
            height={1080}
          />
        </ImageContainer>
      </ErrorContainer>

      {showCategories && (
        <CategoriesContainer>
          <PopularCategoriesStatic
            headlineTitle={popularCategories?.title ?? ''}
            popularCategories={filteredPopularCategories}
            invertButtonColor={true}
          />
        </CategoriesContainer>
      )}
    </>
  );

  return (
    <>
      <ContentContainerMax>
        <StrapiSeo
          pageTitle={`Digando.com | ${statusCode}`}
          seo={{
            title: `Digando.com | ${statusCode}`,
            image: null,
            description: '',
            url: '',
            canonicals: [],
            datePublished: new Date().toString(),
            dateModified: new Date().toString(),
          }}
          schemaOrg={{ enable: false }}
        />
        {error}
      </ContentContainerMax>
    </>
  );
};

const ErrorTitle = styled.div`
  margin-left: 0;
  ${FontSecondarySemiBold};
  font-size: 40px;
  vertical-align: middle;
  text-align: center;
  line-height: normal;

  @media ${mediaQueries.laptop} {
    font-size: 40px;
    text-align: left;
  }

  @media ${mediaQueries.laptopL} {
    font-size: 50px;
    text-align: left;
  }
`;

const TitleContainer = styled.div`
  grid-area: title;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${mediaQueries.laptop} {
    justify-content: left;
  }
`;

const ButtonContainer = styled.div`
  ${spacingTopBottom(1)};
  grid-area: home;
  align-items: center;
  display: block;
  cursor: pointer;
  text-align: center;
  justify-content: center;
  width: 100%;

  button {
    width: 100%;
  }

  @media ${mediaQueries.tablet} {
    display: flex;
  }

  @media ${mediaQueries.laptop} {
    ${spacingTopBottom(4)};
    display: block;
    width: 60%;
  }
`;

const TextContainer = styled.div`
  ${spacingTopBottom(2)};
  grid-area: text;
  display: block;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;

  > div {
    margin: 0 0;
    max-width: 90%;
    ${spacingTopBottom(2)};
  }

  @media ${mediaQueries.laptop} {
    ${spacingTopBottom(4)};
    justify-content: left;
    text-align: left;
    width: 60%;

    > div {
      max-width: 650px;
    }
  }

  @media ${mediaQueries.laptopL} {
    justify-content: left;
    text-align: left;
    width: 60%;
  }
`;

const ImageContainer = styled.div`
  ${spacingTopBottom(6)};
  grid-area: img;
  display: flex;
  justify-content: center;

  @media ${mediaQueries.laptop} {
    ${spacingTopBottom(2)};
  }

  img {
    height: auto;
    width: 80%;

    @media ${mediaQueries.laptop} {
      width: 90%;
    }
  }
`;

const CategoriesContainer = styled.div`
  ${spacingBottom(4)};
  grid-column: auto / span 12;
`;

const ErrorContainer = styled.div<{ showCategories: boolean, statusCode: number }>`
  ${spacingTop(4)};
  ${(p) => p.showCategories ? spacingBottom(0) : spacingBottom(4)};
  grid-column: auto / span 12;
  display: grid;
  grid-template-columns: auto;
  grid-template-areas:
        "title"
        "img"
        "text"
        "home";

  @media ${mediaQueries.laptop} {
    ${spacingTop(8)};
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: ${(p) => 500 === p.statusCode ? '1fr 3fr 1fr 1fr' : 'repeat(4, 1fr)'};
    grid-template-areas:
        "title img"
        "text img"
        "home img"
        "empty img";
  }
`;

export default ErrorPageTemplate;
